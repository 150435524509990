<script setup>
	const props = defineProps({
		exhibition: {
			type: Object,
			required: true,
			default: () => ({}),
		},
		soldOut: {
			type: Boolean,
			default: false,
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});

	const { exhibition, soldOut } = toRefs(props);

	const isExpired = ref(false);

	const validateExhibition = computed(() => {
		if (!exhibition.value?.content) return null;
		if (!exhibition.value?.content?.name) return null;
		if (!exhibition.value?.content?.data) return null;
		return exhibition;
	});

	const formattedExhibition = computed(() => {
		if (!validateExhibition) return null;

		let rootPost = exhibition.value?.content;
		if (!rootPost?.data?.isPermanent) {
			let rawStart = rootPost?.data?.startDate ? new Date(rootPost?.data?.startDate) : null;
			let rawEnd = rootPost?.data?.endDate ? new Date(rootPost?.data?.endDate) : null;
			let startMonth = '';
			let endMonth = '';

			if (rawStart) {
				startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(rawStart);
				if (rawEnd) {
					const yearStart = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(rawStart);
					const yearEnd = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(rawEnd);
					if (yearStart === yearEnd) {
						rootPost.startDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' }).format(rawStart);
					} else {
						rootPost.startDate = new Intl.DateTimeFormat('en-US', {
							month: 'long',
							day: 'numeric',
							year: 'numeric',
						}).format(rawStart);
					}
				} else {
					rootPost.startDate = new Intl.DateTimeFormat('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric',
					}).format(rawStart);
				}

				rootPost.startTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' }).format(rawStart);
			}
			if (rawEnd) {
				endMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(rawEnd);

				if (startMonth === endMonth) {
					rootPost.endDate = new Intl.DateTimeFormat('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric',
					}).format(rawEnd);
				} else {
					rootPost.endDate = new Intl.DateTimeFormat('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric',
					}).format(rawEnd);
				}

				rootPost.endTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' }).format(rawEnd);
			}

			if (rawStart && rawEnd) {
				const timeNow = new Date();
				isExpired.value = timeNow > rawEnd && timeNow > rawStart;
			}
		}

		return rootPost;
	});

	const formatSlide = (item) => {
		return {
			image: {
				url: item.image,
			},
			caption: item.caption,
			alt: item?.alt || '',
		};
	};

	const formattedPrice = computed(() => {
		let pricesArr;
		if (exhibition?.value?.content?.data?.tessituraSync?.prices) {
			pricesArr = exhibition?.value?.content?.data?.tessituraSync?.prices.map((price) => price.price);
		} else if (exhibition?.value?.content?.data?.price) {
			pricesArr = Object.values(exhibition?.value?.content?.data?.price);
		}

		if (pricesArr && pricesArr?.length > 0) {
			const min = Math.min(...pricesArr);
			const max = Math.max(...pricesArr);
			return `$${min}-$${max}`;
		}
		return '';
	});
</script>

<template>
	<section class="block block-exhibition-hero bg-white" tabindex="-1">
		<div class="container" v-if="validateExhibition">
			<ProjectPill
				v-if="isExpired || formattedExhibition?.data?.isArchived"
				text="ARCHIVED"
				bgColor="var(--gray-700)"
				textColor="white"
			/>
			<ProjectPill v-else text="ON VIEW NOW" bgColor="var(--gray-100)" textColor="var(--gray-900)" />
			<h2 v-if="formattedExhibition?.data?.title" v-html="formattedExhibition?.data?.title"></h2>
			<div class="content-and-carousel-wrapper">
				<div class="content">
					<div class="summary rich-text body-lg">
						<p
							v-if="formattedExhibition?.data?.shortDescription"
							v-html="formattedExhibition?.data?.shortDescription"
						></p>
					</div>

					<div class="details">
						<div class="dates-wrapper" v-if="!formattedExhibition?.data?.isPermanent">
							<p class="h6">Date</p>
							<p class="h4">
								<span v-if="formattedExhibition?.startDate" v-html="formattedExhibition.startDate"></span>
								<span v-if="formattedExhibition?.endDate" v-html="' - ' + formattedExhibition.endDate"></span>
							</p>
						</div>

						<div class="location-wrapper">
							<p class="h6">Location</p>
							<p
								v-if="formattedExhibition?.data?.location"
								v-html="formattedExhibition.data.location"
								class="body-lg"
							></p>
						</div>

						<div v-if="formattedPrice && !isExpired" class="price-wrapper">
							<p class="h6">Center Admission Price</p>
							<p v-html="formattedPrice" class="body-lg"></p>
						</div>

						<template v-if="!isExpired">
							<ProjectButton
								v-if="!soldOut"
								label="buy tickets for the center"
								link="https://cart.architecture.org/74189/74190?_ga=2.259573642.1665216199.1717702826-466880857.1717702826&_gl=1*bleyo3*_ga*NDY2ODgwODU3LjE3MTc3MDI4MjY.*_ga_KTTG0HKRNT*MTcxNzcwMjgyNS4xLjEuMTcxNzcwMjg0OS4zNi4wLjA"
								title="Apply"
								version="red-fill"
								class="fs-14"
							/>
							<div v-else>
								<h3 class="h4">This Exhibition is Sold Out</h3>
								<p>Sign up to get notified of future exhibitions!</p>
								<FormKit type="form" name="soldOutForm" @submit="save" :actions="false">
									<FormKit
										name="soldOutEventEmail"
										type="email"
										label="Email Address"
										placeholder="Please enter your email address"
										validation="email"
										validation-visibility="live"
										outer-class="email-outer"
									/>
									<FormKit
										type="submit"
										label="Submit"
										name="emailSubmit"
										wrapper-class="project-button red"
										@submit="save"
									/>
								</FormKit>
							</div>
						</template>
					</div>
				</div>

				<div class="carousel">
					<ProjectCarousel
						v-if="formattedExhibition?.data?.featuredImages"
						:items="formattedExhibition?.data?.featuredImages"
						:bullets="true"
						bulletStyle="secondary"
						arrowPrefix="arrow"
					>
						<template #item="data">
							<FragmentsCarouselsSlideWithCaption v-if="data[0]" :item="formatSlide(data[0])" />
						</template>
					</ProjectCarousel>
					<div v-else>
						<h2>No images in the Image List</h2>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss">
	.block-exhibition-hero {
		// &.block {
		// 	padding: 8rem 0 6rem;
		// 	@media (min-width: $phone) {
		// 		padding: 6rem 0;
		// 	}
		// }
		.pill {
			margin-bottom: 1.5rem;
		}
		.content-and-carousel-wrapper {
			@media (min-width: $mobile) {
				display: flex;
			}
			.content {
				margin-bottom: 30px;

				@media (min-width: $mobile) {
					flex: 0 0 50%;
					margin-bottom: 0;
				}
				@media (min-width: $tablet) {
					flex: 1 1 555px;
				}

				.summary,
				.dates-wrapper {
					margin-bottom: 20px;

					@media (min-width: $mobile) {
						margin-bottom: 35px;
					}

					.h4 {
						span {
							font-size: inherit;
							font-weight: inherit;
							text-transform: none;
						}
					}
				}

				.times-wrapper,
				.location-wrapper {
					margin-bottom: 20px;
				}

				.price-wrapper {
					margin-bottom: 30px;

					@media (min-width: $mobile) {
						margin-bottom: 45px;
					}
				}
			}

			.carousel {
				@media (min-width: $mobile) {
					width: 50%;
					margin-left: 20px;
				}

				@media (min-width: $tablet) {
					flex: 1 1 785px;
				}

				.mess-image img.full-sized-image {
					overflow: hidden;
				}
			}
		}

		form {
			width: 100%;
			margin: 15px -15px -15px -15px;

			@media (min-width: 800px) {
				grid-column: 4 / span 6;
			}

			@media (min-width: $tablet) {
				display: flex;
			}

			.formkit-outer {
				align-self: flex-end;
				margin: 15px;
			}

			.formkit-label {
				font-size: calc(14 / var(--base-fs) * 1rem);
				font-weight: 900;
				line-height: 1.78571;
				letter-spacing: 1.12px;
				text-transform: uppercase;
				margin-bottom: 0.5em;
			}

			input[type='text'],
			input[type='email'] {
				width: 100%;
				padding: 10px 16px;
				background-color: var(--white);
				border: 1px solid var(--black);
				border-radius: 0;
			}

			.email-outer {
				width: 100%;
			}

			.project-button {
				padding: 0;

				button {
					cursor: pointer;
					padding: 12px 28px;
					font-size: 12px;
					font-weight: 900;
					text-transform: uppercase;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>
